@import 'bootstrap';
@import 'helpers.scss';

body {
  font-family: monospace;
  margin: 0;
  font-size: 1.3rem;
  font-weight: normal;
}

code {
  font-family: Consolas, 'courier new', monospace;
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  display: flex;
}

body > canvas {
  display: block;
  position: fixed;
  // simplistic fix to os scaling problem
  width: 100vw !important;
  height: 100vh !important;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:radial-gradient(circle, rgba(50, 33, 67, 0) 55%, rgb(0 0 0 / 65%) 100%);
  z-index: 1;
  pointer-events: none;
}

code {
  font-family: Consolas, 'courier new', monospace;
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
}

#webgl-error-message {
  text-align: center;
  background: #f1f1f1;
  color: #121212;
  padding: 1.5em;
  width: 400px;
  margin: 5em auto 0;
}
// debug dat gui always on top
.dg {
  z-index: 9999 !important;
}

.loader-screen {
  position: absolute;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#100e0e, #000507);

  .loader-wrapper {
    text-align: center;
    color: #efefef;

    > img {
      width: 25vw;
      min-width: 300px;
    }

    .loader-text {
      transform: translate(0, -100%);
      font-size: 0;
      opacity: 0;
      text-shadow: 0 0 20px black;
    }
  }

  .loader {
    display: flex;
    height: 2px;

    margin: 2rem 0;

    div {
      flex: 1;
      background: rgb(255, 72, 0);
      animation: go 1200ms infinite alternate linear;
      box-shadow: 0 0 20px 2px red;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: 50ms * (10 - $i);
        }
      }
    }

    @keyframes go {
      100% {
        background: transparent;
        flex: 10;
        box-shadow: 0 0 0 transparent;
      }
    }
  }
}

.main-content{
  opacity: 0;
  z-index: 2;
  color: #efefef;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.welcome-message{
  position: absolute;
  bottom:25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0 5rem 0 5rem;

}

.toolbar{
  position: absolute;
  bottom:0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem;

  > .item {
    margin: 0 1rem 0 1rem;
  }
}

.main-nav{
  opacity: 0;
  position: absolute;
  bottom:30%;
  width: 100%;
  display: flex;
  justify-content: center;
  > .item {
    margin: 1rem 2rem;
    font-size: 2rem;
    text-transform: uppercase;

  }
}

.full-screen-modal{
  position: absolute;
  top:0;
  backdrop-filter: blur(3px);
  z-index: 2;
  background: rgba(17, 17, 17, 0.72);


  .poster-gif{
    border: 3px solid $primary;
    box-shadow: 0 0 5px 2px $primary;
  }
}

.contact-section {
  min-height: 100vh;
  min-width: 100%;

  .gradient {
    //background: linear-gradient(90deg, rgba(191,22,22,0) 0%, rgba(0,0,0,0.8) 86%);
  }
}

@keyframes shake-vertical {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  30% {
    transform: translateX(-50%) translateY(-4px);
  }
  60% {
    transform: translateX(-50%) translateY(4px);
  }
  80% {
    transform: translateX(-50%) translateY(0px);
  }
  90% {
    transform: translateX(-50%) translateY(0px);
  }
}

#scroll-down-icon{
  position: fixed;
  bottom:5%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  animation: shake-vertical 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s infinite;
  opacity: 0.5;
}
